import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Button } from "../components/button"
import { FitnessPass } from "../components/fitness-pass"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  BackgroundFadedTitle,
  Bold,
  ClassCard,
  ClassCardsContainer,
  HeroButtons,
  HeroRow,
  RightPane,
  Row,
  ScheduleIntroListItem,
  Section,
  Subtitle,
  Testimonial,
  Title,
} from "../components/styled"
import { svgs } from "../components/svgs"
import { colors } from "../constants/colors"
import { mq } from "../constants/media-queries"
import { zIndexes } from "../constants/variables"
import fitgirl from "../images/fitness.png"
import fitsummer from "../images/fitsummer.jpg"

const _react = React

const Hero = styled.div`
  background-image: linear-gradient(
    220deg,
    ${colors.greyC} 0%,
    ${colors.greyF} 90%
  );
  height: 150vw;
  max-height: 90vh;
  padding-top: 80px;
  ${mq.sm`
    height: 80vh;
    max-height: unset;
    padding-top: 15vh;
  `}
`

const FitnessRightPane = styled(RightPane)``

const Fitgirl = styled.img`
  grid-area: 1/1/2/2;
  width: 45%;
  height: 80%;
  justify-self: center;
  transform: translate(2vw, 3vh);
  z-index: ${zIndexes.low};
  ${mq.md`
  width: 60%;
  height: 100%;
  justify-self: center;
  transform: translate(4vw, 3vh);
  `}
  ${mq.lg`
  width: 80%;
  height: 120%;
  `}
  object-fit: contain;
`

const TriangleSvg = styled.div`
  grid-area: 1/1/2/2;
  display: flex;
  align-items: center;
  height: 85%;
  width: 85%;
  transform: rotate(90deg) translateX(-7vw);
  z-index: ${zIndexes.low};
  ${mq.md`
  transform: rotate(90deg) translate(-7vw, 4vw);
  height: 100%;
  width: 100%;
  `} ${mq.lg`
  height: 120%;
  width: 120%;
  `} svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const FitnessBackgroundFadedTitle = styled(BackgroundFadedTitle)`
  font-size: 10rem;
`

const FitnessTestimonial = styled(Testimonial)`
  color: ${colors.black4};
  text-transform: uppercase;
  text-align: center;
  font-size: 8vw;
  ${mq.sm`
    font-size: 3rem;
    padding: 0;  
    `}
  ${mq.md`
  text-align: start;
    font-size: 3.5rem;
  `}
  ${mq.lg`
    font-size: 3.8rem;
  `}
`

const ClassesButtonBox = styled.div`
  margin-top: 30px;
  ${mq.sm`
  margin-top: 4vw;
`}
  ${mq.lg`
  margin-top: 60px;
`}
  display: flex;
  justify-content: center;
`

const PasseSportContainer = styled.div`
  display: grid;
  justify-items: center;
  row-gap: 60px;
  margin-bottom: 80px;
  ${mq.sm`
  padding: 20px;
  grid-template-columns: 45% 1fr;
  column-gap:60px;
  row-gap: 40px;
  align-items: center;
  justify-items: center;
  `};
  div:first-child {
    ${mq.sm`
    grid-column: 1/2;
    grid-row: 1/3;
    `}
  }
`

const PasseSportIntro = styled.p`
  grid-column: 1/2;
  grid-row: 1/2;
  ${mq.sm`
  grid-column: 2/3;
  grid-row: 1/2;
`};
  font-family: "Yellowtail", cursive;
  font-size: 36px;
  line-height: 1.2;
  text-align: center;
`

const PasseSportInfo = styled.div`
  grid-column: 1/2;
  grid-row: 3/4;
  ${mq.sm`
  grid-column: 2/3;
  grid-row: 2/3`};
  display: grid;
  gap: 10px;
  font-size: 18px;
  line-height: 1.5;
`

const PassFullContainer = styled(PasseSportContainer)`
  display: grid;
  margin-bottom: 60px;
  padding: 0;
  ${mq.sm`
  grid-template-columns: 1fr 45%;
  row-gap: 0;
  `}
`

const PassFullInfo = styled(PasseSportInfo)`
  grid-column: 1/2;
  grid-row: 1/2;
  font-size: 22px;
`

const PassInsurance = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  font-style: italic;
`

const Summerbox = styled.div`
  display: grid;
  gap: 20px;
  ${mq.sm`
  grid-template-columns: 1fr 1fr;
  `}
  justify-items: center;
  align-items: center;
  font-family: "Lato", sans-serif;
  line-height: 1.4;
  font-size: 18px;
  img {
    width: 100%;
    object-fit: contain;
    border-radius: 16px;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      transform: translateY(-5px);
    }
  }
  ul {
    margin-top: 30px;
  }
`

const navItems = [
  {
    title: "Horaires",
    path: "/fitness/horaires",
  },
  {
    title: "Infos",
    path: "/infos",
  },
]

const animTime = 500

const FitnessPage = ({ data }) => {
  const { sanityFitnessHeroButton, allSanityFitnessClassCard } = data
  return (
    <Layout navItems={navItems}>
      <SEO title="Fitness" />
      <Hero id="hero">
        <HeroRow>
          <FitnessTestimonial>Vivez la forme avec nous</FitnessTestimonial>
          <FitnessRightPane>
            <FitnessBackgroundFadedTitle>fitness</FitnessBackgroundFadedTitle>
            <TriangleSvg>{svgs.triangle}</TriangleSvg>
            <Fitgirl src={fitgirl} alt="fitness"></Fitgirl>
          </FitnessRightPane>
          <HeroButtons>
            <Button to="/infos" color="pink">
              Rejoignez-nous
            </Button>
            {!sanityFitnessHeroButton?.hidden &&
              sanityFitnessHeroButton?.title &&
              sanityFitnessHeroButton?.linkto && (
                <Button
                  color="black2"
                  outline="true"
                  to={sanityFitnessHeroButton?.linkto}
                >
                  {sanityFitnessHeroButton?.title}
                </Button>
              )}
          </HeroButtons>
        </HeroRow>
      </Hero>
      <Section
        id="classes"
        style={{ zIndex: zIndexes.veryLow, position: "relative" }}
      >
        <Row>
          <Subtitle>Fitness</Subtitle>
          <Title>Les Cours</Title>
          <ClassCardsContainer>
            {allSanityFitnessClassCard.classesCards.map(({ node }, index) => (
              <li
                data-sal="slide-up"
                data-sal-duration={animTime}
                data-sal-delay={`${index}00`}
                data-sal-easing="ease-out"
                key={node.title}
              >
                <ClassCard hasDesc={!!node.desc}>
                  <p className="front">{node.title}</p>
                  {node.desc && <p className="back">{node.desc}</p>}
                </ClassCard>
              </li>
            ))}
          </ClassCardsContainer>
          <ClassesButtonBox
            data-sal="slide-up"
            data-sal-duration={animTime}
            data-sal-delay={`500`}
            data-sal-easing="ease-out"
          >
            <Button to="/fitness/horaires" color="pink" outline="true">
              VOIR LES HORAIRES
            </Button>
          </ClassesButtonBox>
        </Row>
      </Section>
      <Section id="prices" darker>
        <Row>
          <Subtitle>Fitness</Subtitle>
          <Title>Les Formules</Title>
          <PasseSportContainer>
            <FitnessPass></FitnessPass>
            <PasseSportIntro>
              “ Avec votre Passe-Sport, <br /> voyagez d'un cours à l'autre à
              votre guise ! “
            </PasseSportIntro>
            <PasseSportInfo>
              <p>
                La carte Passe-Sport vous donne accès à <Bold>10</Bold> séances
                au choix entre Septembre et Juin.
              </p>
              <p>
                La deuxième carte coûte <Bold>65€</Bold> et la troisième
                seulement <Bold>60€</Bold>.
              </p>
            </PasseSportInfo>
          </PasseSportContainer>
          <PassFullContainer>
            <PassFullInfo>
              <p>
                Et pour les plus motivés, la carte G-Full donne accès à{" "}
                <Bold>TOUS</Bold> les cours.
              </p>
            </PassFullInfo>
            <FitnessPass gold></FitnessPass>
          </PassFullContainer>
          <PassInsurance>
            Pour les deux formules, 10€ d'assurance sont à payer lors du 1er
            cours
          </PassInsurance>
        </Row>
      </Section>
      <Section id="summer">
        <Row>
          <Subtitle>Fitness</Subtitle>
          <Title>Bougez l'été</Title>
          <Summerbox>
            <div
              data-sal="slide-right"
              data-sal-duration={animTime}
              data-sal-delay={`300`}
              data-sal-easing="ease-out"
            >
              <img src={fitsummer} alt="groupe de fitness" />
            </div>
            <div>
              <p>
                Rejoignez-nous tout l'été pour des séances de{" "}
                <Bold>tonification</Bold>
              </p>
              <ul>
                <ScheduleIntroListItem>
                  {svgs.checkmark_outline} tous les MARDIS de juillet et août de
                  20h à 21h15
                </ScheduleIntroListItem>
                <ScheduleIntroListItem>
                  {svgs.checkmark_outline} au Centre Sportif Jean Moisse à
                  Mont-St-Guibert
                </ScheduleIntroListItem>
                <ScheduleIntroListItem>
                  {svgs.checkmark_outline} 8€ la séance (à régler sur place)
                </ScheduleIntroListItem>
              </ul>
            </div>
          </Summerbox>
        </Row>
      </Section>
    </Layout>
  )
}

export default FitnessPage

export const pageQuery = graphql`
  query {
    allSanityFitnessClassCard {
      classesCards: edges {
        node {
          title
          desc
        }
      }
    }
    sanityFitnessHeroButton {
      title
      hidden
      linkto
    }
  }
`
