import React from "react"
import styled, { css } from "styled-components"
import { colors } from "../constants/colors"
import { mq } from "../constants/media-queries"
import logopass from "../images/logopass.png"

const _react = React

const PassContainer = styled.div`
  display: grid;
  transition: all 0.3s ease;
  &:hover,
  &:focus {
    transform: translateY(-5px);
  }
`

const Pass = styled.div`
  grid-column: 1/2;
  grid-row: 1/2;
  width: 300px;
  height: 178px;
  ${mq.sm`
  width: 320px;
  height: 190px;
  `}
  background: ${({ gold }) =>
    gold
      ? css`linear-gradient(285.1deg, #D0AD51 1.82%, #DFC070 44.43%, #D3AF51 94.27%)`
      : css`linear-gradient(90deg, #fafafa 0%, ${colors.white} 90%)`};
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
`

const PassFront = styled(Pass)`
  grid-column: 1/2;
  grid-row: 1/2;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  transform: rotate(-5deg);
  .price {
    font-size: 25px;
    color: ${({ gold }) => (gold ? colors.black2 : colors.purple)};
    position: absolute;
    right: 18px;
    bottom: 16px;
  }
  .stripe {
    height: 100%;
    width: 16px;
    transform: skewX(-15deg);
    position: absolute;
    &:first-of-type {
      background: ${colors.purple};
      left: 6px;
    }
    &:nth-of-type(2) {
      background: ${colors.lightPurple};
      left: 21px;
    }
    &:nth-of-type(3) {
      background: ${colors.lightPink};
      left: 36px;
      width: 14px;
    }
  }
  .logopass {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    img {
      transform: translateX(10%);
      height: 50%;
      width: 50%;
      object-fit: contain;
    }
    .name {
      margin-top: 10px;
      font-family: "Yellowtail", cursive;
      font-size: 25px;
      transform: translateX(-5%);
    }
  }
`

export const FitnessPass = ({ gold }) => {
  return (
    <div data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease-out">
      <PassContainer>
        <PassFront gold={gold}>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="logopass">
            <img src={logopass} alt="passe sport logo" />
            <p className="name">{gold ? "G-Full" : "Passe-Sport"}</p>
          </div>
          <p className="price">{gold ? "315€" : "80€"}</p>
        </PassFront>
        <Pass gold={gold}></Pass>
      </PassContainer>
    </div>
  )
}
